/* Global Styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #000; /* Black background */
  padding: 20px;
  margin: 0;
  color: #fff; /* White text */
}

/* Header and wallet button */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Container Styling */
.container {
  max-width: 400px;
  margin: 0 auto;
  background-color: #1c1c1c; /* Dark grey for the container */
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  border: none;
  color: #fff; /* Ensure text inside container is white */
}

/* Section Styling for Staking Details */
.stake-section {
  margin-bottom: 30px;
}

.stake-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ccc; /* Light grey headings */
}

/* Box Styling */
.box {
  background-color: #333;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  text-align: center;
}

.box h2, .box p {
  margin: 0;
  color: #fff; /* White text in the box */
}

.box h2 {
  font-size: 22px;
  font-weight: bold;
}

.box p {
  font-size: 14px;
  margin-top: 5px;
  color: #ccc; /* Light grey for the description */
}

/* Input Box Styling */
 input[type="number"] {
  width: 90%;
  padding: 15px;
  margin: 10px 0;
  background-color: #333; /* Dark grey background */
  color: #fff; /* White text */
  border: none;
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5); /* Slight shadow for depth */
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

input[type="number"]:focus {
  outline: none;
  background-color: #444; /* Slightly lighter grey on focus */
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.6); /* Deeper shadow on focus */
}

/* Button Styling */
button {
  width: 100%;
  margin: 10px 0;
  padding: 15px;
  background-color: #00FFAB; /* Bright green for the buttons */
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #00d88b; /* Darker green on hover */
  box-shadow: 0 4px 12px rgba(0, 255, 171, 0.4);
}

/* Stake and Unstake Button Section */
.stake-section button {
  width: 48%;
  margin: 0 1% 10px 1%;
  display: inline-block;
}

.stake-section button:hover {
  background-color: #00d88b; /* Match hover effect */
}

/* Mobile-Responsive */
@media (max-width: 768px) {
  .container {
    max-width: 90%;
    padding: 20px;
  }

  .stake-section button {
    width: 100%;
    margin: 10px 0;
  }
}
.stake-section, .unstake-section, .withdraw-section {
  border: 2px solid #00FFAB; /* Add green border */
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #bec4cf;
  background: linear-gradient(135deg, #0d1120 0%, #3a4b8a 43%, #0d1120 100%);
  padding: 1.5rem;
  border-radius: 1.2rem;
}
.bgblue {
  background: linear-gradient(135deg, #fffffff5, #3a4b8a, #ffffff98);
  padding: 1px;
  border-radius: 1.2rem;
  box-shadow: 0px 1rem 1.5rem -0.9rem #000000e1;
  max-width: 300px;
}

